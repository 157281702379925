import {type Inline} from '@englex/slate';

import {SlateInline, SlateObject} from '../../../../interface';
import {type BlockNormalizer, ButtonType} from '../../interface';

const blockNormalizer: BlockNormalizer = {
  [SlateInline.ICON]: null,
  [SlateInline.LABEL]: null,
  [SlateInline.LINK]: {
    predicate: ({child}) =>
      !!child && child.object === SlateObject.INLINE && child.type === ButtonType.LINK,
    reasons: {
      child_object_invalid: (change, {child}) => {
        change.unwrapInlineByKey(child!.key, ButtonType.LINK);
      }
    }
  },
  [SlateInline.GAP]: {
    predicate: ({child}) =>
      !!child && child.object === SlateObject.INLINE && child.type === SlateInline.GAP,
    reasons: {
      child_object_invalid: (change, {child}) => {
        change
          .moveToRangeOfNode(child!)
          .moveToStartOfNextText()
          .insertText(((child! as Inline).data.get('answer') as string[])?.[0] || '')
          .removeNodeByKey(child!.key);
        return true;
      }
    }
  }
};

export default blockNormalizer;
