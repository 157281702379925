import React from 'react';
import {Editor as SlateReactEditor, type Plugin, type EventHook} from '@englex/slate-react';
import {
  type Value,
  type ValueJSON,
  type Next,
  type Editor as EditorType,
  type Node
} from '@englex/slate';
import withDevTools from '@englex/slate-dev-tools/lib/withDevTools';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import Toolbar from './plugins/Toolbar';
import CollapseOnEscape from './plugins/CollapseOnEscape';
import Wrapper from '../plugins/Wrapper';
import ToolBox from './plugins/ToolBox';
import HtmlSerializer from './plugins/HtmlSerializer';
import SchemaNormalizer from './plugins/SchemaNormalizer/index';
import {getPluginsRecursive} from '../utils';
import {type SlateReactChange} from '../interface';
import hyperscript from '../hyperscript';
import PasteFragment from './plugins/PasteFragment';
import ImageDropResolver from './plugins/ImageDropResolver';
import Placeholder from './plugins/Placeholder';
import {slateDevToolsEnabled} from '../utils/slateDevTools';
import {TextNormalizer} from '../plugins/TextNormalizer';
import {type TextNormalizerOptions} from '../plugins/interface';
import {VoidInlineSelected} from './plugins/button/VoidInlineSelected';
import GetWidgetProps from '../plugins/GetWidgetProps';

import './SlateEditor.scss';

interface ToolbarOptions {
  hide?: boolean;
  portalId?: string;
}

interface Props {
  value: Value;
  valueJSON?: ValueJSON;
  placeholder?: string;
  whenPlaceholder?: (editor: EditorType, node: Node) => boolean;
  readOnly?: boolean;
  toolbar?: ToolbarOptions;
  bsSize?: 'sm' | 'lg';
  onChange?: (change: SlateReactChange) => void;
  plugins?: Plugin[];
  spellcheck?: boolean;
  className?: string;
  autoFocus?: boolean;
  onKeyDown?: EventHook<React.KeyboardEvent>;
  textNormalizerOptions?: TextNormalizerOptions;
  getWidgetProps?: () => WidgetTypeComponentProps;
}

interface State {
  value: Value | null;
}

class SlateEditor extends React.Component<Props, State> {
  public readonly plugins: Plugin[] = [];

  constructor(props: Props) {
    super(props);

    const propsPlugins: Plugin[] = props.plugins || [];

    const plugins = [
      new TextNormalizer(props.textNormalizerOptions),
      new CollapseOnEscape(),
      new ImageDropResolver(),
      new Wrapper({
        bsSize: props.bsSize
      }),
      new Toolbar({
        show: props.toolbar ? !props.toolbar.hide : true,
        portalId: props.toolbar ? props.toolbar.portalId : undefined
      }),
      new ToolBox(),
      Placeholder({placeholder: props.placeholder, when: props.whenPlaceholder}),
      VoidInlineSelected(),

      ...propsPlugins,

      new HtmlSerializer(),
      new PasteFragment()
    ];

    if (props.getWidgetProps) {
      plugins.push(new GetWidgetProps({getWidgetProps: props.getWidgetProps}));
    }

    getPluginsRecursive(this.plugins, plugins);

    this.plugins = [new SchemaNormalizer(this.plugins), ...this.plugins];

    this.state = {
      value: null
    };
  }

  public get value(): Value {
    return this.state.value || this.props.value;
  }

  public render() {
    return (
      <Editor
        autoFocus={this.props.autoFocus}
        value={this.value}
        onChange={this.onChange}
        plugins={this.plugins}
        readOnly={this.props.readOnly}
        className={this.props.className}
        spellCheck={this.props.spellcheck}
        onKeyDown={this.onKeyDown}
      />
    );
  }

  private onKeyDown: EventHook<React.KeyboardEvent> = this.props.onKeyDown
    ? this.props.onKeyDown
    : (e: React.KeyboardEvent, editor: SlateReactEditor, next: Next) => next();

  private onChange = (change: SlateReactEditor) => {
    if (this.props.onChange) {
      this.props.onChange(change);
    } else {
      this.setState({value: change.value});
    }
  };
}
const Editor = withDevTools({
  hyperprintOptions: {
    hyperscript
  },
  enabled: slateDevToolsEnabled()
})(SlateReactEditor);

export default SlateEditor;
