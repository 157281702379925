import {type Plugin} from '@englex/slate-react';
import {type Editor} from '@englex/slate';

import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import NestedToolbar from '../NestedToolbar';
import Decimal from './Decimal';
import Alpha from './Alpha';
import {listIsActive, listIsDisabled} from './utils';
import {ListType} from '../../../../interface';

class OrderedList extends NestedToolbar {
  public plugins: Plugin[] = [new Decimal({toolbar: this}), new Alpha({toolbar: this})];

  public icon = 'list-ol';
  public list = ListType.OL;
  public title = buttonTitle.OrderedList;
  public type = ButtonType.ORDERED_LIST;

  public isActive = (editor: Editor) => {
    if (this.isDisabled(editor)) {
      return false;
    }

    return listIsActive(editor, {list: this.list});
  };

  public isDisabled(editor: Editor) {
    const {document, startBlock} = editor.value;
    if (super.isDisabled(editor) || !startBlock) {
      return true;
    }
    return listIsDisabled(document, startBlock, this.list);
  }
}

export default OrderedList;
