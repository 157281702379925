import {Block, Editor, type Next, type Value} from '@englex/slate';
import {type Editor as ReactEditor, getEventTransfer, type Plugin} from '@englex/slate-react';
import Html, {type SerializeRules} from '@englex/slate-html-serializer';
import type React from 'react';

import {getHtmlRules, SlateBlock} from '../../interface';
import {editHTMLFromMSWord, isPastingFromMSWord} from './utils';
import {isBlockOfType} from '../../utils';

export function collapseEmptyBlocks(change: Editor) {
  change.value.document.getBlocks().forEach(block => {
    const isEmptyDefaultBlock =
      block && isBlockOfType(block, SlateBlock.DEFAULT) && block.text.match(/^\s+$/);

    if (isEmptyDefaultBlock) {
      change.replaceNodeByKey(
        block!.key,
        Block.create({key: block!.key, type: SlateBlock.DEFAULT})
      );
    }
  });
  return change;
}

class HtmlSerializer implements Plugin {
  private serializer?: Html;

  public getSerializer = (editor: Editor) => {
    if (!this.serializer) {
      const rules = editor.query<SerializeRules | undefined>(getHtmlRules) || [];

      this.serializer = new Html({rules});
    }

    return this.serializer;
  };

  public onPaste = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    const transfer = getEventTransfer(event);
    const html = transfer.html;

    if (transfer.type !== 'html' || !html) {
      return next();
    }

    const serializer = this.getSerializer(change);
    const editedHTML = isPastingFromMSWord(html) ? editHTMLFromMSWord(html) : html;
    const htmlValue = serializer.deserialize(editedHTML) as Value;
    const fragmentEditor = new Editor({value: htmlValue});
    const fragment = fragmentEditor.command(collapseEmptyBlocks).value.document;

    if (import.meta.env.MODE === 'development') {
      const parser = new DOMParser();
      /* eslint-disable no-console */
      console.groupCollapsed('Paste HTML:');
      console.log('HTML:', html);
      console.log('DOM:', parser.parseFromString(html, 'text/html'));
      console.log('SLATE:', fragment);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    change.insertFragment(fragment);
    return;
  };
}

export default HtmlSerializer;
