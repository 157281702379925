import {type Plugin, type Editor, type Mark} from '@englex/slate';
import {OrderedSet} from 'immutable';

import {getVoidInlineSelectedOnlyMarks, isVoidInlineSelectedOnly} from 'components/Slate/interface';

export const VoidInlineSelected = (): Plugin => {
  return {
    queries: {
      [isVoidInlineSelectedOnly]: (editor: Editor): boolean => {
        const {selection} = editor.value;
        if (selection && selection.isCollapsed) {
          const inlines = editor.value.inlines;
          if (inlines.size === 1) {
            const inline = inlines.first();
            if (inline.nodes.size === 1 && editor.query('isVoid', inline)) {
              return true;
            }
          }
        }
        return false;
      },
      [getVoidInlineSelectedOnlyMarks]: (editor: Editor): OrderedSet<Mark> => {
        if (!editor.query(isVoidInlineSelectedOnly)) {
          return editor.value.activeMarks;
        }
        const marks = editor.value.texts.first().marks;
        return marks?.size ? OrderedSet(marks) : OrderedSet();
      }
    }
  };
};
