import React from 'react';
import {type Editor as ReactEditor, type EditorProps, type Plugin} from '@englex/slate-react';
import {type Editor, type Next} from '@englex/slate';

import {type ToolBoxChildrenProps} from './interface';
import SlateToolBox from './components/SlateToolBox';

type ToolBoxOpenFn = <P extends object>(
  component: React.ElementType<P & ToolBoxChildrenProps>,
  props: P,
  className?: string
) => void;

class ToolBox implements Plugin {
  queries = {
    getToolBox: () => this
  };

  public renderEditor = (props: EditorProps, editor: ReactEditor & Editor, next: Next) => {
    return (
      <React.Fragment>
        {next()}
        <SlateToolBox ref={this.toolboxRef} editor={editor} />
      </React.Fragment>
    );
  };

  public open: ToolBoxOpenFn = (component, props, className) => {
    if (this.toolboxRef.current) {
      this.toolboxRef.current.open(component, props, className);
    }
  };

  public close = (restoreEditorFocus: boolean = true) => {
    if (this.toolboxRef.current) {
      this.toolboxRef.current.close(restoreEditorFocus);
    }
  };

  private toolboxRef = React.createRef<SlateToolBox>();
}

export default ToolBox;
