import {buttonTitle} from '../i18n';
import {ListType} from '../../../../interface';
import DecimalRenderer from '../../../../plugins/renderers/List/Decimal';

import List from '.';

class Decimal extends List {
  public icon = 'list-numbers';
  public list = ListType.OL;
  public shortcut = 'mod+shift+8';
  public title = buttonTitle.OrderedListDecimal;

  public plugins = [new DecimalRenderer()];
}

export default Decimal;
