import {ListStyleType} from '../../../../interface';
import AlphaRenderer from '../../../../plugins/renderers/List/Alpha';
import Decimal from './Decimal';
import {buttonTitle} from '../i18n';

class Alpha extends Decimal {
  public icon = 'list-letters';
  public listStyleType = ListStyleType.ALPHA;
  public shortcut = 'mod+shift+9';
  public title = buttonTitle.OrderedListAlpha;

  public plugins = [new AlphaRenderer()];
}

export default Alpha;
