import {Block, type Text} from '@englex/slate';

import {SlateBlock, SlateObject} from '../../../../interface';
import {type DocumentNormalizer} from '../../interface';
import {isBlockOfType, isDefaultBlock, isIconInline} from '../../../../utils';

const documentNormalizer: DocumentNormalizer = {
  [SlateObject.DOCUMENT]: {
    predicate: ({node}) => !!node && node.object === SlateObject.DOCUMENT,
    reasons: {
      child_min_invalid: (change, {node}) => {
        change.insertNodeByKey(node.key, 0, Block.create(SlateBlock.DEFAULT)).focus();
        return true;
      },
      node_mark_invalid: (change, {node, mark}) => {
        // from slate defaultNormalize function
        node.getTexts().forEach((t: Text) => change.removeMarkByKey(t.key, 0, t.text.length, mark));
        return true;
      }
    }
  },
  [SlateBlock.DEFAULT]: null,
  [SlateBlock.EXAMPLE]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.EXAMPLE),
    reasons: {
      child_type_invalid: (change, {child}) => {
        if (child && isBlockOfType(child, SlateBlock.EXAMPLE)) {
          change.unwrapBlockByKey(child.key, {type: SlateBlock.EXAMPLE});
          return true;
        }
        return false;
      }
    }
  },
  [SlateBlock.IMAGE]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.IMAGE),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.removeNodeByKey(child!.key);
        return true;
      }
    }
  },
  [SlateBlock.LIST]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.LIST),
    reasons: {
      child_type_invalid: (change, {child}) => {
        if (isBlockOfType(child!, SlateBlock.LIST)) {
          change.unwrapBlockByKey(child.key, {type: SlateBlock.LIST});
          return true;
        }
        return false;
      }
    }
  },
  [SlateBlock.LIST_ITEM]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.LIST_ITEM),
    reasons: {
      child_type_invalid: (change, {child}) => {
        if (isBlockOfType(child!, SlateBlock.LIST_ITEM)) {
          change.unwrapBlockByKey(child.key, {type: SlateBlock.LIST_ITEM});
          return true;
        }
        return false;
      }
    }
  },
  [SlateBlock.DIALOG_TABLE]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.DIALOG_TABLE),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.unwrapBlockByKey(child!.key, {type: SlateBlock.DIALOG_TABLE});
        return true;
      }
    }
  },
  [SlateBlock.DIALOG_ROW]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.DIALOG_ROW),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.unwrapBlockByKey(child!.key, {type: SlateBlock.DIALOG_ROW});
        return true;
      }
    }
  },
  [SlateBlock.DIALOG_CELL]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.DIALOG_CELL),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.unwrapBlockByKey(child!.key, {type: SlateBlock.DIALOG_CELL});
        return true;
      }
    }
  },
  [SlateBlock.QUESTION_LIST]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.QUESTION_LIST),
    reasons: {
      child_type_invalid: (change, {child}) => {
        const previousNode = change.value.document.getPreviousNode(child!.key);
        const previousNodeIsEmpty =
          previousNode &&
          isDefaultBlock(previousNode) &&
          previousNode.text === '' &&
          !previousNode.getInlines().find(node => isIconInline(node!));

        change.unwrapBlockByKey(child!.key, {type: SlateBlock.QUESTION_LIST});
        if (previousNodeIsEmpty && change.value.document.getNode(previousNode!.key)) {
          change.removeNodeByKey(previousNode!.key);
        }
        return true;
      }
    }
  },
  [SlateBlock.QUESTION_ITEM]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.QUESTION_ITEM),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.unwrapBlockByKey(child!.key, {type: SlateBlock.QUESTION_ITEM});
        return true;
      }
    }
  },
  [SlateBlock.QUESTION_ANSWER]: {
    predicate: error => !!error.child && isBlockOfType(error.child, SlateBlock.QUESTION_ANSWER),
    reasons: {
      child_type_invalid: (change, {child}) => {
        change.unwrapBlockByKey(child!.key, {type: SlateBlock.QUESTION_ANSWER});
        return true;
      }
    }
  }
};

export default documentNormalizer;
