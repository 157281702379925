import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import classNames from 'classnames';
import {type Editor} from '@englex/slate';
import './SlateToolBox.scss';

interface Props {
  editor?: Editor;
}

interface State {
  component: React.ElementType | null;
  componentProps: object | null;
  className?: string;
  freeze: boolean;
}

class SlateToolBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      component: null,
      componentProps: null,
      className: undefined,
      freeze: false
    };
  }

  public render() {
    const classes = classNames('slate-toolbox', {[this.state.className!]: !!this.state.className});

    if (!this.state.component) {
      return null;
    }

    const ToolBoxComponent = this.state.component;

    return (
      <Modal
        backdrop="static"
        className={classes}
        show={true}
        onHide={this.close}
        {...{restoreFocus: false}}
      >
        <ToolBoxComponent close={this.close} freeze={this.freeze} {...this.state.componentProps} />
      </Modal>
    );
  }

  public close = (restoreEditorFocus: boolean = true) => {
    if (this.state.freeze) {
      return;
    }
    this.setState(
      {
        component: null,
        componentProps: null
      },
      () => {
        if (restoreEditorFocus) {
          this.restoreEditorFocus();
        }
      }
    );
  };

  public open = (component: React.ElementType, componentProps: object, className?: string) => {
    this.setState({
      component,
      componentProps,
      className
    });
  };

  private freeze = (shouldFreeze: boolean) => this.setState({freeze: shouldFreeze});

  private restoreEditorFocus = () => {
    if (!this.props.editor) return;

    // if just the one inline is selected, then set caret just after the inline
    if (
      this.props.editor.value.selection?.isCollapsed &&
      this.props.editor.value.inlines.count() === 1
    ) {
      this.props.editor.moveToStartOfNextText().focus();
    } else {
      this.props.editor.focus();
    }
  };
}

export default SlateToolBox;
