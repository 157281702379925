import {type Plugin} from '@englex/slate-react';
import {Block, type Editor, type Node, type SlateError} from '@englex/slate';

import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../interface';
import {getListItemBlockOfBlock} from './utils';
import {isBlockOfType, isListBlock, isListItemBlock} from '../../../../utils';
import {SlateBlock, SlateObject} from '../../../../interface';
import RenderListItem from '../../../../plugins/renderers/List/ListItem';

class ListItem implements SchemaBlockPlugin, Plugin {
  public plugins = [new RenderListItem()];
  public block = SlateBlock.LIST_ITEM;

  public blockRules = (): SchemaBlockRules => ({
    type: this.block,
    rules: {
      data: {
        style: () => true
      },
      nodes: [
        // at first, one or more paragraphs, and then maybe one embedded list
        {
          match: [{type: SlateBlock.DEFAULT}],
          min: 1
        },
        {
          match: [{type: SlateBlock.LIST}],
          min: 0,
          max: 1
        }
      ],
      parent: {type: SlateBlock.LIST}
    },
    normalizer: {
      predicate: ({node}: SlateError) => !!node && isListItemBlock(node),
      reasons: {
        parent_type_invalid: (change: Editor, {node}: SlateError) => {
          change.unwrapBlockByKey(node.key, {
            type: this.block
          });
          return true;
        },
        child_min_invalid: (change: Editor, error: SlateError) => {
          change.removeNodeByKey(error.node.key);
          return true;
        },
        child_type_invalid: (change: Editor, {child, node}: SlateError) => {
          if (!child) {
            return;
          }
          if (child.object === SlateObject.TEXT) {
            change.removeNodeByKey(node.key);
            return true;
          }
          if (isListBlock(child)) {
            change.insertNodeByKey(node.key, 0, Block.create({type: SlateBlock.DEFAULT}));
            return true;
          }
          if (isBlockOfType(child, SlateBlock.DEFAULT)) {
            return this.handleParagraphChild(change, node, child);
          }
          return;
        },
        child_unknown: (change: Editor, {child, node}: SlateError) => {
          if (child && isBlockOfType(child, SlateBlock.DEFAULT)) {
            return this.handleParagraphChild(change, node, child);
          }
          return;
        },
        child_max_invalid: (change: Editor, {child}: SlateError) => {
          if (isListBlock(child)) {
            if (isListBlock(change.value.document.getPreviousSibling(child.key))) {
              change.mergeNodeByKey(child.key);
              return true;
            }
          }
          return;
        }
      }
    }
  });

  private handleParagraphChild = (change: Editor, node: Node, child: Block) => {
    // if paragraph or image goes in li after embedded list, move it to the last block of this embedded list
    const prevBlock = change.value.document.getPreviousBlock(child.key);
    if (prevBlock) {
      const targetLi = getListItemBlockOfBlock(change.value.document, prevBlock);
      if (targetLi) {
        change.moveNodeByKey(child.key, targetLi.key, targetLi.nodes.size);
        return true;
      }
    }
    return;
  };
}

export default ListItem;
