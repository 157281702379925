import {
  type Editor,
  type NormalizeFn,
  type Rules,
  type SchemaProperties,
  type SlateError
} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import {SlateBlock, SlateObject} from '../../interface';
import {
  type ObjectNormalizer,
  type SchemaBlockPlugin,
  type SchemaInlinePlugin,
  type SchemaMarkPlugin
} from '../plugins/interface';
import documentNormalizer from '../plugins/SchemaNormalizer/normalizer/document';
import blockNormalizer from '../plugins/SchemaNormalizer/normalizer/block';
import {logNormalizeError} from '../plugins/utils';

const normalizeObject = (
  type: 'document' | 'block',
  objectNormalizer: ObjectNormalizer,
  change: Editor,
  error: SlateError
) => {
  let handled = false;
  Object.keys(objectNormalizer).forEach(key => {
    const normalizer = objectNormalizer[key];
    if (normalizer) {
      const {predicate, reasons} = normalizer;
      const normalize = reasons[error.code];
      if (predicate(error) && normalize) {
        handled = Boolean(normalize(change, error));
        if (handled) {
          logNormalizeError(error, type, handled, key);
        }
      }
    }
  });
  if (!handled) {
    logNormalizeError(error, type, handled);
  }
};

const normalizeDocument: (o?: ObjectNormalizer) => NormalizeFn = o => (change, error) =>
  normalizeObject('document', o ? o : documentNormalizer, change, error);

const normalizeBlock: (o?: ObjectNormalizer) => NormalizeFn = o => (change, error) =>
  normalizeObject('block', o ? o : blockNormalizer, change, error);

const allowedInlines = (plugins: Plugin[]) => {
  const inlinePlugins = plugins.filter(
    (plugin: Plugin & SchemaInlinePlugin) => plugin.inlineRules
  ) as SchemaInlinePlugin[];
  const inlineExtenders = inlinePlugins.map((plugin: SchemaInlinePlugin) => plugin.inlineRules());
  const inlinesRules = inlineExtenders.reduce<{[key: string]: Rules}>(
    (schemaInlines, inlineExtender) => {
      schemaInlines[inlineExtender.type] = inlineExtender.rules;
      return schemaInlines;
    },
    {}
  );

  return {
    inlineTypes: Object.keys(inlinesRules).map(inlineType => ({type: inlineType})),
    inlines: inlinesRules
  };
};

const allowedBlocks = (plugins: Plugin[]) => {
  const blockSchemaExtenders = plugins.filter(
    (plugin: Plugin & SchemaBlockPlugin) => plugin.blockRules
  ) as SchemaBlockPlugin[];
  const blockExtenders = blockSchemaExtenders.map((plugin: SchemaBlockPlugin) =>
    plugin.blockRules()
  );
  const blocksRules = blockExtenders.reduce<{[key: string]: Rules}>(
    (schemaBlocks, blockExtender) => {
      const {inlineTypes} = allowedInlines(plugins);
      const normalizer = blockExtender.normalizer
        ? {[blockExtender.type]: blockExtender.normalizer}
        : {};
      schemaBlocks[blockExtender.type] = {
        normalize: normalizeBlock(normalizer),
        nodes: [
          {
            match: [{object: SlateObject.TEXT}, ...inlineTypes]
          }
        ],
        ...blockExtender.rules
      };
      return schemaBlocks;
    },
    {}
  );

  return {
    blockTypes: Object.keys(blocksRules).map(blockType => ({type: blockType})),
    blocks: blocksRules
  };
};

const allowedMarks = (plugins: Plugin[]): Array<{type: string}> => {
  const markPlugins = plugins.filter(
    (plugin: Plugin & SchemaMarkPlugin) => plugin.markRules
  ) as SchemaMarkPlugin[];
  return markPlugins.map((plugin: SchemaMarkPlugin) => plugin.markRules());
};

const generateSchema = (plugins: Plugin[]): SchemaProperties => {
  const {blockTypes, blocks} = allowedBlocks(plugins);
  const {inlineTypes, inlines} = allowedInlines(plugins);

  return {
    document: {
      marks: allowedMarks(plugins),
      nodes: [
        {
          match: [{type: SlateBlock.DEFAULT}, ...blockTypes],
          min: 1
        }
      ],
      normalize: normalizeDocument()
    },
    blocks: {
      [SlateBlock.DEFAULT]: {
        nodes: [
          {
            match: [{object: SlateObject.TEXT}, ...inlineTypes]
          }
        ],
        normalize: normalizeBlock()
      },
      ...blocks
    },
    inlines
  };
};

export default generateSchema;
