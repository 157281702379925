import {type Editor as ReactEditor, getEventTransfer, type Plugin} from '@englex/slate-react';
import {Editor, Document, Value, type Next} from '@englex/slate';
import type React from 'react';

import {getBeforeInsertFragmentPlugins} from 'components/Slate/interface';

import {type InsertFragmentPlugin} from './interface';

class PasteFragment implements Plugin {
  /**
   * Run onBeforeInsertFragment on paste fragment event
   *
   * @param {Event} event
   * @param {module:slate.Editor} change
   * @param {module:slate.Next} next
   * @returns {boolean}
   */
  public onPaste = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    const transfer = getEventTransfer(event);

    const {fragment} = transfer;
    if (transfer.type !== 'fragment' || !fragment) {
      return next();
    }

    let fragmentChange = this.getFragmentChange(fragment);

    const plugins = (change.query(getBeforeInsertFragmentPlugins) as InsertFragmentPlugin[]) || [];

    const preventInsert = plugins.reduce<Document | boolean | undefined | void>(
      (handled, p: InsertFragmentPlugin) => {
        if (handled) {
          return handled;
        }
        handled = p.onBeforeInsertFragment(event, fragmentChange, change);
        if (handled instanceof Document) {
          // plugin has replaced fragment with another fragment, so replace temp change for the next plugins
          fragmentChange = this.getFragmentChange(handled);
          return undefined;
        }
        return handled;
      },
      undefined
    );

    if (!preventInsert) {
      // paste fragment with cloned gap IDs
      event.preventDefault();
      change.insertFragment(fragmentChange.value.document);
      return;
    }

    return;
  };

  private getFragmentChange(fragment: Document) {
    return new Editor({value: Value.create({document: fragment})});
  }
}

export default PasteFragment;
