import React from 'react';
import {type Editor as ReactEditor, type EditorProps, type Plugin} from '@englex/slate-react';
import classNames from 'classnames';
import {Portal} from 'react-portal';
import {type Editor, type Next} from '@englex/slate';

import {getToolbarButtons, getToolbarPortalId} from 'components/Slate/interface';

import type ToolbarButton from './ToolbarButton';
import SlateToolbar from '../Toolbar/SlateToolbar';

interface ToolbarOptions {
  show?: boolean;
  portalId?: string;
}

class Toolbar implements Plugin {
  private readonly show: boolean = false;
  private readonly renderInPortalId: string | null = null;

  public queries = {
    [getToolbarPortalId]: () => this.renderInPortalId
  };

  constructor(options: ToolbarOptions = {}) {
    this.show = !!options.show;
    this.renderInPortalId = options.portalId || null;
  }
  public renderEditor = (props: EditorProps, editor: ReactEditor & Editor, next: Next) => {
    const plugins = editor.query(getToolbarButtons, true) as ToolbarButton[];
    const className = classNames('slate-editor-toolbar');

    if (!plugins?.length) {
      return next();
    }

    const portal = this.renderInPortalId ? document.getElementById(this.renderInPortalId) : null;
    const ToolbarNode = (
      <SlateToolbar
        className={className}
        show={this.show}
        plugins={plugins}
        editor={editor}
        hideOnEditorBlur={!!this.renderInPortalId}
      />
    );
    const ToolbarWrapper = portal ? <Portal node={portal}>{ToolbarNode}</Portal> : ToolbarNode;
    return (
      <React.Fragment>
        {ToolbarWrapper}
        {next()}
      </React.Fragment>
    );
  };
}

export default Toolbar;
