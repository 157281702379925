import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';
import {type Editor, type Next} from '@englex/slate';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

type Edge = 'Start' | 'End' | 'Anchor' | 'Focus';

interface Options {
  edge: Edge;
}

/**
 * Collapse selection on 'Escape'
 */
class CollapseOnEscape implements Plugin {
  protected shortcut = 'esc';
  private readonly edge: Edge;

  public constructor(options?: Options) {
    this.edge = options ? options.edge : 'End';
  }
  public onKeyDown = (event: React.KeyboardEvent, editor: ReactEditor & Editor, next: Next) => {
    if (!isShortcut(event, this.shortcut)) {
      return next();
    }

    const {value} = editor;
    const {selection, document, startText, endText} = value;
    if (!startText || !endText) {
      return next();
    }
    if (selection?.isCollapsed) {
      // for void inline: when cursor is in void inline block, we should just move to start of next text
      if (document.hasVoidParent(startText.key, editor)) {
        editor.moveToStartOfNextText();
        return;
      }
    } else {
      // for void inline: if endText is in void inline block we should just move to start of next text
      if (document.hasVoidParent(endText.key, editor)) {
        editor.moveToStartOfNextText();
        return;
      }

      // just collapse
      const method = `moveTo${this.edge}`;
      editor[method]();
      return;
    }

    return;
  };
}

export default CollapseOnEscape;
