import React from 'react';
import {type Block, type Editor, type Next, type Node} from '@englex/slate';
import {type RenderDecorationProps} from '@englex/slate-react';
import SlateReactPlaceholder, {type SlatePlaceholderOptions} from '@englex/slate-react-placeholder';

import {SlateBlock, SlateDecoration, SlateObject, getPlaceholder} from '../../interface';

interface PlaceholderOptions extends Partial<SlatePlaceholderOptions> {
  placeholder?: string;
  when?: (editor: Editor, node: Node) => boolean;
}

/**
 * Default when query
 *
 * @param editor slate:Editor
 * @param node slate:Node
 */
function defaultWhen({value: {document}}: Editor, node: Node) {
  return (
    node.object === SlateObject.DOCUMENT &&
    document.text === '' &&
    document.getInlines().size === 0 &&
    document.nodes.size === 1 &&
    (document.nodes.get(0) as Block).type === SlateBlock.DEFAULT
  );
}

function Placeholder(options: PlaceholderOptions) {
  if (typeof options.placeholder === 'undefined') {
    return {};
  }
  const {placeholder, when = defaultWhen, ...rest} = options;

  const opts = {
    when,
    placeholder,
    style: {width: '100%', opacity: 1, whiteSpace: 'normal', verticalAlign: 'top'},
    ...rest
  } as SlatePlaceholderOptions;

  function renderDecoration(
    {children, attributes, decoration: {type}}: RenderDecorationProps,
    editor: Editor,
    next: Next
  ) {
    if (type === SlateDecoration.PLACEHOLDER) {
      return (
        <span {...attributes}>
          {children}
          <span className="slate-placeholder" contentEditable={false}>
            {next()}
          </span>
        </span>
      );
    }

    return next();
  }

  return [
    {
      renderDecoration,
      queries: {[getPlaceholder]: () => placeholder}
    },
    SlateReactPlaceholder({...opts})
  ];
}

export default Placeholder;
