import {buttonTitle} from '../i18n';
import {ListType} from '../../../../interface';
import RenderUnorderedList from '../../../../plugins/renderers/List/UnorderedList';

import List from './index';

class UnorderedList extends List {
  public list = ListType.UL;
  public icon = 'list-ul';
  public title = buttonTitle.UnorderedList;
  public shortcut = 'mod+shift+7';

  public plugins = [new RenderUnorderedList()];
}

export default UnorderedList;
