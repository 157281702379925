import {type Plugin} from '@englex/slate-react';
import {type SchemaProperties} from '@englex/slate';

import generateSchema from '../../schema/generator';

class SchemaNormalizer implements Plugin {
  public schema: SchemaProperties;
  private enabled = true;

  constructor(plugins: Plugin[]) {
    if (this.enabled) {
      this.schema = generateSchema(plugins);
    }
  }
}

export default SchemaNormalizer;
